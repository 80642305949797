import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_month from '../../../../../../../common/src/assets/image/example_month.png';
import template_options_month from '../../../../../../../common/src/assets/image/template_options_month.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Month template style generates a calendar showing an entire month.
        Each event or task will be ordered based on its starting time. All day
        events will be placed at the top of each day cell.
      </p>
      <p>
        <Image alt="" src={example_month} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Month template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_month} style={{ width: 444 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start output at</strong>
            </td>
            <td>
              Options are <strong>Current month</strong>,{' '}
              <strong>Next month</strong>, or <strong>Selected month</strong>.
              When Selected month is picked, the <strong>Start month</strong>{' '}
              option will be shown.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Start month</strong>
            </td>
            <td>The first month to show in the generated output.</td>
          </tr>
          <tr>
            <td>
              <strong>Number of months</strong>
            </td>
            <td>
              Multiple months can be generated. Each month will be placed on its
              own worksheet (Excel) or page (Word). You can select how many
              months to include in the output by clicking the drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>The first day of the week will be the left-most day column.</td>
          </tr>
          <tr>
            <td>
              <strong>Min rows per day</strong>
            </td>
            <td>
              Each day cell will consist of the minimum number of specified
              rows. The higher the value, the more events will be able to fit,
              but larger values might it more difficult to fit the calendar on a
              single page when printing.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max rows per day</strong>
            </td>
            <td>
              This is the maximum number of rows to be used for each day cell.
              If a date has more events than the maximum number of rows, an
              indication will be shown at the bottom of the day cell.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              Titles can be wrapped (if space allows), never wrapped, or shrunk
              to fit so that wrapping is unnecessary. You can pick the desired
              behavior via the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item sort order</strong>
            </td>
            <td>
              Sort items by either start time or calendar data source. When
              sorted by calendar data source, items will be effectively grouped
              based on their associated calendar data source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>If an item doesn't fit</strong>
            </td>
            <td>
              Select what to do when an item won't fit within the specified{' '}
              <strong>Max rows per day</strong>. Options are{' '}
              <strong>Show "+ More" indicator</strong> or{' '}
              <strong>Overflow to additional page</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background color for all-day items</strong>
            </td>
            <td>
              When checked, only events or tasks that are all-day or span
              multiple days will have a background color. Other items will have
              their text colored without a background.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>
              <span>When checked, event or task end times will be shown. </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions within items</strong>
            </td>
            <td>
              When checked, descriptions will be shown within items if space is
              available.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions in tooltips</strong>
            </td>
            <td>
              When checked, an item's description will be added to a comment
              that pops up when hovering the mouse over the item. Note, this
              only works when calendars are generated in Excel, not Word. Also,
              these comments won't be included in the printout.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Condense unused rows</strong>
            </td>
            <td>
              When this option is checked, any unused rows in day cells will be
              deleted, allowing the month to be dynamically sized so it can fit
              better when printing. Note, blank rows will not be removed if this
              would result in fewer rows than specified by the{' '}
              <strong>Min rows per day</strong> value.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Clip items in alternate months</strong>
            </td>
            <td>
              When checked, events or tasks that occur in the previous or
              next month will not be shown.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Month-1-Month-Page"
      commentsId="commentsplus_post_130_489"
      title="Month Template"
      description="The Month template style generates a printable calendar showing an entire month. Each event or task will be ordered based on its starting time."
      keywords="month template, print month, printable month, printable calendar, Excel calendar, Word calendar, generate month calendar, create month calendar, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
